export default function PopularFeatures() {
  return (
    <div className="w-full overflow-hidden">
      <div className="w-full overflow-hidden  bg-no-repeat bg-cover pc:bg-[url('/public/bg2.svg')] md:bg-[url('/public/bg.svg')] bg-[url('/public/bg3.svg')] flex flex-col items-center">
        <h1 className="m-0 px-[1rem] text-center pc:mb-[5.75rem] mb-[5rem] pc:mt-[8rem] md:mt-[10rem] mt-[8.1rem] pc:font-bold font-extrabold pc:text-[2.625rem] text-[2.125rem] leading-[3rem] font-body-lg-medium text-[#18181B]">
          Features our users love
        </h1>
        <div className="grid pc:grid-cols-3 md:grid-cols-2 grid-cols-1 pc:grid-rows-2 md:grid-rows-3 grid-rows-6 pc:gap-[8rem] gap-[3rem] pc:gap-y-[7rem] gap-y-[2rem] md:mb-[5.25rem] mb-[3rem]">
          <Card
            title="Track Rewards"
            imageSource="/track.svg"
            description="Track all your loyalty rewards — airlines, hotels, and credit cards — in
        one place."
          />
          <Card
            title="Transfer Points"
            imageSource="/transfer.svg"
            description="Convert your points from one loyalty program to other. know your points value."
          />
          <Card
            title="Convert Points"
            imageSource="/convert.svg"
            description="Need more points?  Magnify guides you on where to convert points from."
          />
          <Card
            title="Indirect Transfers"
            imageSource="/indirect.svg"
            description="Transfer points across multiple programs. Maximize your rewards with indirect transfer."
          />
          <Card
            title="Manage Vouchers"
            imageSource="/vouchers.svg"
            description="Add your vouchers to Magnify and we will remind you before it expires."
          />
          <Card
            title="Transfer Time"
            imageSource="/time.svg"
            description="Track how long your point transfers take, so you’re always in control."
          />
        </div>
      </div>
    </div>
  );
}

type CardProps = {
  title: string;
  description: string;
  imageSource: string;
};

function Card({ title, description, imageSource }: CardProps) {
  return (
    <div className="w-[20rem] pt-[1rem] p-6 rounded-2xl hover:shadow-lg hover:transform hover:scale-105 transition-all duration-300 ease-in-out"> {/* Added hover effects */}
      <div className="m-0 w-[5.3rem] h-[5.3rem] relative pc:mx-0 mx-auto rounded-full mb-[2.5rem] flex justify-center items-center">
        <img
          src="/small-gradient-bg.svg"
          className="w-[5.3rem] h-[5.3rem] blur-xl rounded-full"
          alt=""
        />
        <img
          src="/small-gradient-bg.svg"
          className="w-[5rem] h-[5rem] absolute blur-lg  rounded-full"
          alt=""
        />
        <div
          className={`absolute w-[4.625rem] h-[4.625rem] rounded-full bg-white flex justify-center items-center`}
        >
          <img src={imageSource} alt="" />
        </div>
      </div>
      <h2 className="m-0 pc:text-left text-center  mb-[1.5rem] font-body-lg-medium text-[1.25rem] font-bold text-[#111827] leading-[1.75rem]">
        {title}
      </h2>
      <p className="m-0 pc:text-left text-center  text-[1rem] font-body-lg-medium font-medium leading-[1.75rem] text-[#52525B]">
        {description}
      </p>
    </div>
  );
}