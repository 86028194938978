import { useEffect, useState } from "react";
import NumberRoller from "../RollingNumbers/rolling-numbers";
import { useDeviceType } from "../../lib/hooks/useDeviceType";

type RewardsData = {
  totalReward: number | null;
  airlineSum: number | null;
  hotelSum: number | null;
  cardSum: number | null;
};

export default function RewardTracked() {
  const [headingAnimationProps, setHeadingAnimationProps] = useState({
    containerClasses: `text-[4rem] font-inter mb-[1rem] font-extrabold text-right`,
    size: 48,
  });

  const [rewardsData, setRewardsData] = useState<RewardsData>({
    totalReward: null,
    airlineSum: null,
    hotelSum: null,
    cardSum: null,
  });

  const fetchRewardsData = async () => {
    try {
      // const response = await fetch(
      //   `http://localhost:3000/api/reward/rewardlogs`
      // );
      // const data = await response.json();
      // setRewardsData(data.data[0]);
      setRewardsData({
        totalReward: 13500,
        airlineSum: 4000,
        hotelSum: 3000,
        cardSum: 6500,
      });
    } catch (error) {}
  };

  useEffect(() => {
    fetchRewardsData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1440 && window.innerWidth >= 768) {
        setHeadingAnimationProps({
          containerClasses: `font-inter mb-[1rem] font-bold`,
          size: 28,
        });
      } else if (window.innerWidth >= 1440) {
        setHeadingAnimationProps({
          containerClasses: `font-inter mb-[1rem] font-semibold text-right `,
          size: 64,
        });
      } else {
        setHeadingAnimationProps({
          containerClasses: `font-inter mb-[1rem] font-bold`,
          size: 40,
        });
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  return (
    <div className="flex flex-col items-center justify-center font-body-lg-medium pt-[6rem] pb-[3.625rem]">
      <p className="md:m-0 m-0 mb-[0.5rem] text-[1.125rem] text-[#52525B] text-center px-[1rem] pc:p-0">
        Get access to multiple rewards in various categories
      </p>
      <div className="px-[1rem] pc:p-0">
        <div className="flex justify-center md:mt-[1.5rem] mt-[0.7rem] w-fit">
          <div className="flex-row block md:flex w-fit">
            <div className="flex justify-center h-fit">
              <div
                className={`pc:h-[4.8rem] md:h-[2.2rem] h-[3rem] pc:w-[15rem] md:w-[6.6rem] w-[9.4rem] md:mt-[0.8rem] pc:mt-0`}
              >
                <NumberRoller
                  containerClasses={headingAnimationProps.containerClasses}
                  number={rewardsData.totalReward ?? 3000}
                  size={headingAnimationProps.size}
                />
              </div>
              <img
                className="pc:mt-[1.4rem] md:mt-[1.37rem] mt-[0.8rem] pc:h-[2.2rem] md:h-[1rem] h-[1.5rem] pc:w-[2.2rem] md:w-[1rem] w-[1.5rem] pc:ml-[0.2rem] ml-[0.25rem]"
                alt=""
                src="/+.svg"
              />
            </div>
            <h1 className="md:m-0 m-0 pc:pt-[1.1rem] md:pt-[0.8rem] pt-[0.2rem] text-[#111827] pc:text-[2.625rem] md:text-[1.75rem] text-[2.1rem] font-extrabold pc:leading-[2.75rem] md:leading-[2rem] leading-[2.5rem] pc:pl-[0.7rem] pl-[0.45rem] md:text-left text-center">
              Rewards tracked on Magnify
            </h1>
          </div>
        </div>
      </div>
      <div className="grid md:grid-cols-3 grid-cols-1 md:grid-rows-1 grid-rows-3 pc:gap-[2.5rem] gap-0 mt-[3.5rem]">
        <Card
          imageSource="/room-door.svg"
          message="Total Hotel rewards tracked"
          number={rewardsData.hotelSum ?? 1000}
        />
        <Card
          imageSource="/plane-wing.svg"
          message="Total Airline rewards tracked"
          number={rewardsData.airlineSum ?? 1000}
        />
        <Card
          imageSource="/credit-card.svg"
          message="Total Card rewards tracked"
          number={rewardsData.cardSum ?? 1000}
        />
      </div>
    </div>
  );
}

type CardProps = {
  imageSource: string;
  message: string;
  number: number;
};

function Card({ imageSource, message, number }: CardProps) {
  const { isMobile, isDesktop } = useDeviceType();
  const cardsAnimationContainerClasses = `font-inter mb-[1rem] font-semibold text-right`;
  const size = isDesktop ? 48 : isMobile ? 48 : 34;

  return (
    <div className="pc:w-[22rem] md:w-[15.5rem] w-[22rem] pc:h-[22rem] md:h-[15.5rem] h-[22rem] flex justify-center items-center relative">
      <img
        src={"/gradient-shadow.svg"}
        className="pc:w-[20.5rem] pc:h-[20.5rem] pc:mr-[1.5rem] md:mr-[0.5rem] mr-[1.5rem] md:w-[15rem] w-[20.5rem] md:h-[15rem] h-[20.5rem] blur-3xl"
        alt={`Category Image`}
      />
      <img
        src={"/gradient-shadow.svg"}
        className="absolute pc:left-[0.75rem] md:left-[-0.25rem] left-[0.75rem] pc:top-[0.75rem] md:top-[0.25rem] top-[0.75rem] pc:w-[19.5rem] pc:h-[20.5rem] md:w-[15rem] w-[19.5rem] md:h-[15rem] h-[20.5rem] z-20 blur-md"
        alt={`Category Image`}
      />
      <div className="pc:p-[2.5rem] md:p-[1.29rem] p-[2.5rem] pc:pr-[1.75rem] md:pr-[1rem] pr-[1.75rem] pc:w-[15.25rem] md:w-[11.71rem] w-[15.25rem] shadow-sm pc:h-[14.5rem] md:h-[11.42rem] h-[14.5rem] z-30 bg-white rounded-[1rem] flex flex-col justify-between absolute transition-all duration-300 hover:shadow-lg hover:transform hover:scale-105">
        <img
          className="pc:w-[3rem] md:w-[2.25rem] w-[3rem] pc:h-[3rem] md:h-[2.25rem] h-[3rem] pc:mt-[1rem] md:mt-[0.75rem] mt-[1rem]"
          alt=""
          src={imageSource}
        />
        <div>
          <div className="flex">
            <div
              className={`flex pc:h-[3.7rem] md:h-[2.6rem] h-[3.7rem] w-[8.9rem] md:w-[6.3rem] pc:w-[8.9rem]`}
            >
              <NumberRoller
                number={number}
                containerClasses={cardsAnimationContainerClasses}
                size={size}
              />
            </div>
            <img
              className="pc:mt-[1.1rem] md:mt-[0.7rem] mt-[1.1rem] pc:h-[1.7rem] md:h-[1.2rem] h-[1.7rem] pc:w-[1.7rem] md:w-[1.2rem] w-[1.7rem] ml-[0.3rem] md:ml-[0.3rem] pc:ml-[0.3rem]"
              alt=""
              src="/+.svg"
            />
          </div>
          <p className="m-0 text-[#52525B] font-medium pc:leading-7 md:leading-[1.875rem] leading-7 font-body-lg-medium pc:text-[1.125rem] md:text-[0.875rem] text-[1.125rem]">
            {message}
          </p>
        </div>
      </div>
    </div>
  );
}
