import { FunctionComponent, useRef } from "react";
import HomeContainer from "../../components/home-container";
import Header from "../../components/header";
import BottomFooter from "../../components/bottom-footer";
import RewardTracked from "../../components/RewardsTracked/rewards-tracked";
import LogoCarousel from "../../components/LogoCarousel/logo-carousel";
import PopularFeatures from "../../components/PopularFeatures/popular-features";
import TestimonialsSection from "../../components/TestimonialsSection/testimonials-section";
import { useDeviceType } from "../../lib/hooks/useDeviceType";
import NewsletterForm from "../../components/NewsletterForm/newsletter-form";
import ScrollToTop from "../../components/ScrollToTop/scroll-to-top";

const LandingPage: FunctionComponent = () => {
  const { isMobile } = useDeviceType();
  const newsletterRef = useRef<HTMLDivElement>(null);

  const scrollToNewsletter = () => {
    if (newsletterRef.current) {
      const headerHeight = 72; // 4.5rem in pixels
      const elementPosition =
        newsletterRef.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - headerHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="relative flex flex-col items-center w-full bg-white">
      <img
        src={isMobile ? "/bg_mobile.svg" : "/bg_pc.svg"}
        className="absolute w-full pc:h-screen md:h-[85vh] h-[80rem]"
        alt=""
      />
      <Header onBusinessClassClick={scrollToNewsletter} />
      <HomeContainer />
      <RewardTracked />
      <PopularFeatures />
      <TestimonialsSection />
      <LogoCarousel />
      <div className="w-full bg-white flex justify-center" ref={newsletterRef}>
        <NewsletterForm />
      </div>
      <BottomFooter />
      <ScrollToTop />
    </div>
  );
};

export default LandingPage;
